import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit,Input, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit, AfterViewInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  comentarioForm: FormGroup;
  submitted = false;
  loading = false;
  constructor(private formBuilder: FormBuilder,
              private http: HttpClient,
              private _snackBar: MatSnackBar) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    this.comentarioForm = this.formBuilder.group({
      nombre: [''],
      telefono: [''],
      email: ['', [Validators.email]],
      mensaje: [''],
    });
  }

  get f() { return this.comentarioForm.controls; }

  enviarComentario(){
    this.loading=true
    this.http.post<any>(`${environment.apiUrl}/api/Reserva/sendEmail`, this.comentarioForm.value).subscribe(x => {
      this.loading=false
      this.openSnackBar('Mensaje enviado con exito!');
      this.comentarioForm.reset();
    })
  }
  openSnackBar(msj) {
    this._snackBar.open(msj, 'X', {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
