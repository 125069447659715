import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare function sendMail(environment): any;

@Component({ 
             templateUrl: 'home.component.html',
             styleUrls: ['./home.component.css']
            })
            
export class HomeComponent {
    loading = false;
    users: User[];
    userForEdit=new User;
    editarUs= false;
    submitted = false;
    editForm: FormGroup;
    constructor(private userService: UserService,
                private formBuilder: FormBuilder,
                private router: Router,) { }

    ngOnInit() {
        sendMail(`${environment.apiUrl}`)
    }
    scroll(el: HTMLElement) {
          el.scrollIntoView({behavior: 'smooth'});
    }
}