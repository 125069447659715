import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    errors: any[] = [];
    timeOut = 1500;
    constructor(private authenticationService: AuthenticationService,
                private _snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            try {
                err.error.forEach( (message, index) => {

                setTimeout(() => {
                    this._snackBar.open(message, 'x', {
                        duration: this.timeOut,
                        verticalPosition: 'bottom', // 'top' | 'bottom'
                        horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
                    });
                }, index * (this.timeOut + 1000)); // 500 - timeout between two messages

            });
                const error = err.error.message || err.statusText;
                return throwError(error);
        } catch {
                this._snackBar.open('Hubo un problema, intente de nuevo mas tarde', 'x', {
                    duration: 1500,
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', // 'start' | 'center' | 'end' | 'left' | 'right'
                });
            }

            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                // location.reload(true);
            }


        }));
    }
}
