import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  loading = false;
  submitted = false;
  createForm: FormGroup;
  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,) { }

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      usuario: ['', Validators.required],
      contraseña: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      dni: ['', Validators.required],
      email: ['', Validators.required],
      rol: ['', Validators.required],
    });
    this.loading = true;
  }
  get f() { return this.createForm.controls; }
  
  enviarNewUser(){
    this.userService.crear(this.createForm.value).subscribe(x=>{
      console.log("Creado con exito!")
      this.router.navigate(['/home'])
    })
  }
  volver(){
    this.router.navigate(['/home'])
  }
}
