import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogInComponent } from './components/log-in/log-in.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from '../app/components/_helpers';
import { NewUserComponent } from './components/new-user/new-user.component';
import {LayoutComponent } from './components/layout/layout.component'
const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  //{ path: 'login', component: LogInComponent },
  {path: '',component: LayoutComponent,children: [
    { path: '', component: HomeComponent/*, canActivate: [AuthGuard]*/ },
  //{ path: 'crear-usuario', component: NewUserComponent/*, canActivate: [AuthGuard] */},
  ]}
  //{ path: 'register', component: RegisterComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }