import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/api/User/all`);
    }

    editar(user:User) {
        return this.http.put<User>(`${environment.apiUrl}/api/User/editar`,user);
    }

    crear(user:User) {
        return this.http.post<User>(`${environment.apiUrl}/api/User/crear`,user);
    }
    getUser(id) {
        return this.http.get<User>(`${environment.apiUrl}/api/User/${id}`);
    }
}