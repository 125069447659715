export class User {
    id: number;
    usuario: string;
    contraseña: string;
    nombre: string;
    apellido: string;
    dni: string;
    email: string;
    rol: string;
    token?: string;
}