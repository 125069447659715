import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {DatePipe} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

declare function scroll(): any;

@Component({
	selector: 'app-reservas',
	templateUrl: './reservas.component.html',
	styleUrls: ['./reservas.component.scss'],
})

export class ReservasComponent implements OnInit {
	public carpasAll: any[] = [];
	public libres: any[] = [];
	carpa: any;
	reservaForm: FormGroup;
	carpas: any[];
	exam:any
	submitted = false;
	error = '';
	periodos: any[] = [];
	range = new FormGroup({
		desde: new FormControl(),
		hasta: new FormControl()
	});
	constructor(private formBuilder: FormBuilder,
							public datepipe: DatePipe,
							private http: HttpClient) {
								this.libres = [];
							 }

	ngOnInit(): void {
		scroll()

		this.carpasAll = [
			//Carpas
			//Fila 1 - Impares (0:22)
			{id: 39, nombre: '39', libre: true, seleccionada: false, ocupada: false},
			{id: 37, nombre: '37', libre: true, seleccionada: false, ocupada: false},
			{id: 35, nombre: '35', libre: true, seleccionada: false, ocupada: false},
			{id: 33, nombre: '33', libre: true, seleccionada: false, ocupada: false},
			{id: 31, nombre: '31', libre: true, seleccionada: false, ocupada: false},
			{id: 29, nombre: '29', libre: true, seleccionada: false, ocupada: false},
			{id: 27, nombre: '27', libre: true, seleccionada: false, ocupada: false},
			{id: 25, nombre: '25', libre: true, seleccionada: false, ocupada: false},
			{id: 23, nombre: '23', libre: true, seleccionada: false, ocupada: false},
			{id: 21, nombre: '21', libre: true, seleccionada: false, ocupada: false},
			{id: 19, nombre: '19', libre: true, seleccionada: false, ocupada: false},
			{id: 17, nombre: '17', libre: true, seleccionada: false, ocupada: false},
			{id: 15, nombre: '15', libre: true, seleccionada: false, ocupada: false},
			{id: 13, nombre: '13', libre: true, seleccionada: false, ocupada: false},
			{id: 11, nombre: '11', libre: true, seleccionada: false, ocupada: false},
			{id: 9, nombre: '9', libre: true, seleccionada: false, ocupada: false},
			{id: 7, nombre: '7', libre: true, seleccionada: false, ocupada: false},
			{id: 5, nombre: '5', libre: true, seleccionada: false, ocupada: false},
			{id: 3, nombre: '3', libre: true, seleccionada: false, ocupada: false},
			{id: 1, nombre: '1', libre: true, seleccionada: false, ocupada: false},
			//Fila 1 - Pares (22:44)
			{id: 2, nombre: '2', libre: true, seleccionada: false, ocupada: false},
			{id: 4, nombre: '4', libre: true, seleccionada: false, ocupada: false},
			{id: 6, nombre: '6', libre: true, seleccionada: false, ocupada: false},
			{id: 8, nombre: '8', libre: true, seleccionada: false, ocupada: false},
			{id: 10, nombre: '10', libre: true, seleccionada: false, ocupada: false},
			{id: 12, nombre: '12', libre: true, seleccionada: false, ocupada: false},
			{id: 14, nombre: '14', libre: true, seleccionada: false, ocupada: false},
			{id: 16, nombre: '16', libre: true, seleccionada: false, ocupada: false},
			{id: 18, nombre: '18', libre: true, seleccionada: false, ocupada: false},
			{id: 20, nombre: '20', libre: true, seleccionada: false, ocupada: false},
			{id: 22, nombre: '22', libre: true, seleccionada: false, ocupada: false},
			{id: 24, nombre: '24', libre: true, seleccionada: false, ocupada: false},
			{id: 26, nombre: '26', libre: true, seleccionada: false, ocupada: false},
			{id: 28, nombre: '28', libre: true, seleccionada: false, ocupada: false},
			{id: 30, nombre: '30', libre: true, seleccionada: false, ocupada: false},
			{id: 32, nombre: '32', libre: true, seleccionada: false, ocupada: false},
			{id: 34, nombre: '34', libre: true, seleccionada: false, ocupada: false},
			{id: 36, nombre: '36', libre: true, seleccionada: false, ocupada: false},
			{id: 38, nombre: '38', libre: true, seleccionada: false, ocupada: false},
			{id: 40, nombre: '40', libre: true, seleccionada: false, ocupada: false},

			//Fila 2 - Impares (44:59)
			{id: 93, nombre: '63', libre: true, seleccionada: false, ocupada: false},
			{id: 91, nombre: '61', libre: true, seleccionada: false, ocupada: false},
			{id: 89, nombre: '59', libre: true, seleccionada: false, ocupada: false},
			{id: 87, nombre: '57', libre: true, seleccionada: false, ocupada: false},
			{id: 55, nombre: '55', libre: true, seleccionada: false, ocupada: false},
			{id: 53, nombre: '53', libre: true, seleccionada: false, ocupada: false},
			{id: 51, nombre: '51', libre: true, seleccionada: false, ocupada: false},
			{id: 49, nombre: '49', libre: true, seleccionada: false, ocupada: false},
			{id: 47, nombre: '47', libre: true, seleccionada: false, ocupada: false},
			{id: 45, nombre: '45', libre: true, seleccionada: false, ocupada: false},
			{id: 43, nombre: '43', libre: true, seleccionada: false, ocupada: false},
			{id: 41, nombre: '41', libre: true, seleccionada: false, ocupada: false},
			//Fila 2 - Pares (59:74)
			{id: 42, nombre: '42', libre: true, seleccionada: false, ocupada: false},
			{id: 44, nombre: '44', libre: true, seleccionada: false, ocupada: false},
			{id: 46, nombre: '46', libre: true, seleccionada: false, ocupada: false},
			{id: 48, nombre: '48', libre: true, seleccionada: false, ocupada: false},
			{id: 50, nombre: '50', libre: true, seleccionada: false, ocupada: false},
			{id: 52, nombre: '52', libre: true, seleccionada: false, ocupada: false},
			{id: 54, nombre: '54', libre: true, seleccionada: false, ocupada: false},
			{id: 86, nombre: '56', libre: true, seleccionada: false, ocupada: false},
			{id: 88, nombre: '58', libre: true, seleccionada: false, ocupada: false},
			{id: 90, nombre: '60', libre: true, seleccionada: false, ocupada: false},
			{id: 92, nombre: '62', libre: true, seleccionada: false, ocupada: false},
			{id: 94, nombre: '64', libre: true, seleccionada: false, ocupada: false},

			//Fila 3 - Impares (74:89)
			{id: 115, nombre: '85', libre: true, seleccionada: false, ocupada: false},
			{id: 113, nombre: '83', libre: true, seleccionada: false, ocupada: false},
			{id: 111, nombre: '81', libre: true, seleccionada: false, ocupada: false},
			{id: 109, nombre: '79', libre: true, seleccionada: false, ocupada: false},
			{id: 107, nombre: '77', libre: true, seleccionada: false, ocupada: false},
			{id: 105, nombre: '75', libre: true, seleccionada: false, ocupada: false},
			{id: 103, nombre: '73', libre: true, seleccionada: false, ocupada: false},
			{id: 101, nombre: '71', libre: true, seleccionada: false, ocupada: false},
			{id: 99, nombre: '69', libre: true, seleccionada: false, ocupada: false},
			{id: 97, nombre: '67', libre: true, seleccionada: false, ocupada: false},
			{id: 95, nombre: '65', libre: true, seleccionada: false, ocupada: false},
			//Fila 3 - Pares (89:104)
			{id: 96, nombre: '66', libre: true, seleccionada: false, ocupada: false},
			{id: 98, nombre: '68', libre: true, seleccionada: false, ocupada: false},
			{id: 100, nombre: '70', libre: true, seleccionada: false, ocupada: false},
			{id: 102, nombre: '72', libre: true, seleccionada: false, ocupada: false},
			{id: 104, nombre: '74', libre: true, seleccionada: false, ocupada: false},
			{id: 106, nombre: '76', libre: true, seleccionada: false, ocupada: false},
			{id: 108, nombre: '78', libre: true, seleccionada: false, ocupada: false},
			{id: 110, nombre: '80', libre: true, seleccionada: false, ocupada: false},
			{id: 112, nombre: '82', libre: true, seleccionada: false, ocupada: false},
			{id: 114, nombre: '84', libre: true, seleccionada: false, ocupada: false},
			{id: 116, nombre: '86', libre: true, seleccionada: false, ocupada: false},

			//Sombrillas
			//Fila 0 - Impares (104:119)
			{id: 84, nombre: '29', libre: true, seleccionada: false, ocupada: false},
			{id: 82, nombre: '27', libre: true, seleccionada: false, ocupada: false},
			{id: 80, nombre: '25', libre: true, seleccionada: false, ocupada: false},
			{id: 78, nombre: '23', libre: true, seleccionada: false, ocupada: false},
			{id: 76, nombre: '21', libre: true, seleccionada: false, ocupada: false},
			{id: 74, nombre: '19', libre: true, seleccionada: false, ocupada: false},
			{id: 72, nombre: '17', libre: true, seleccionada: false, ocupada: false},
			{id: 70, nombre: '15', libre: true, seleccionada: false, ocupada: false},
			{id: 68, nombre: '13', libre: true, seleccionada: false, ocupada: false},
			{id: 66, nombre: '11', libre: true, seleccionada: false, ocupada: false},
			{id: 64, nombre: '9', libre: true, seleccionada: false, ocupada: false},
			{id: 62, nombre: '7', libre: true, seleccionada: false, ocupada: false},
			{id: 60, nombre: '5', libre: true, seleccionada: false, ocupada: false},
			{id: 58, nombre: '3', libre: true, seleccionada: false, ocupada: false},
			{id: 56, nombre: '1', libre: true, seleccionada: false, ocupada: false},
			//Fila 0 - Pares (119:134)
			{id: 57, nombre: '2', libre: true, seleccionada: false, ocupada: false},
			{id: 59, nombre: '4', libre: true, seleccionada: false, ocupada: false},
			{id: 61, nombre: '6', libre: true, seleccionada: false, ocupada: false},
			{id: 63, nombre: '8', libre: true, seleccionada: false, ocupada: false},
			{id: 65, nombre: '10', libre: true, seleccionada: false, ocupada: false},
			{id: 67, nombre: '12', libre: true, seleccionada: false, ocupada: false},
			{id: 69, nombre: '14', libre: true, seleccionada: false, ocupada: false},
			{id: 71, nombre: '16', libre: true, seleccionada: false, ocupada: false},
			{id: 73, nombre: '18', libre: true, seleccionada: false, ocupada: false},
			{id: 75, nombre: '20', libre: true, seleccionada: false, ocupada: false},
			{id: 77, nombre: '22', libre: true, seleccionada: false, ocupada: false},
			{id: 79, nombre: '24', libre: true, seleccionada: false, ocupada: false},
			{id: 81, nombre: '26', libre: true, seleccionada: false, ocupada: false},
			{id: 83, nombre: '28', libre: true, seleccionada: false, ocupada: false},
			{id: 85, nombre: '30', libre: true, seleccionada: false, ocupada: false},
		];

		this.reservaForm = this.formBuilder.group({
			periodo: [''],
			desde: [''],
			hasta: [''],
		});

		this.cargarCarpas();
		this.cargarPeriodos();
	}

	get f() { return this.reservaForm.controls; }
	get r() { return this.reservaForm.controls; }

	onSubmit(){
		if(this.f.desde.value && this.f.hasta.value){
			var fechaDesde:any;
			var fechaHasta:any;

			fechaDesde = this.datepipe.transform(this.f.desde.value, 'yyyy-MM-dd HH:mm:ss');
			fechaHasta = this.datepipe.transform(this.f.hasta.value, 'yyyy-MM-dd HH:mm:ss');
			this.http.get(`${environment.apiUrl}/api/Carpa/disponibles/${fechaDesde}/${fechaHasta}`).subscribe(
				(data:any) => {
					this.libres=data;
					for (var i = 0; i < this.carpasAll.length; i++) {
						this.carpasAll[i].libre = false;
					}
					for (var i = 0; i < this.carpasAll.length; i++) {
						for (var x = 0; x < this.libres.length; x++) {
							if (this.carpasAll[i].id == this.libres[x].id) {
								this.carpasAll[i].libre = true;
								this.carpasAll[i].seleccionada = false;
							}
						}
					}
				},
				error=>{console.log(error)
			})
		}
	}

	cargarCarpas() {
		this.http.get<any>(`${environment.apiUrl}/api/Carpa/all`).subscribe(x => {
			this.carpas = x;
		});
	}
	cargarPeriodos() {
		this.http.get<any>(`${environment.apiUrl}/api/Ejercicio/valid`).subscribe(x => {
			this.periodos = x;
			console.log(x);
		});
	}

	// addEvent(event: any) {
	//   if(event.value == 'hoy'){
	//     var hoy = new Date()
	//     this.reservaForm.controls["hasta"].setValue(hoy)
	//     this.reservaForm.controls["desde"].setValue(hoy)
	//   }else{
	//     var fechaHasta = new Date(event.value.hasta)
	//     var fechaDesde = new Date(event.value.desde)
	//     this.reservaForm.controls["hasta"].setValue(fechaHasta)
	//     this.reservaForm.controls["desde"].setValue(fechaDesde)
	//   }
	//   this.onSubmit();
	// }
	addEvent(event: any) {
		if (event.value == 'hoy') {
			let hoy = new Date();
			this.reservaForm.controls.hasta.setValue(hoy);
			this.reservaForm.controls.desde.setValue(hoy);
		} else {
			let obj = JSON.parse(event.value);
			let fechaDesde = new Date(obj.desde);
			let fechaHasta = new Date(obj.hasta);
			this.reservaForm.controls.hasta.setValue(fechaHasta);
			this.reservaForm.controls.desde.setValue(fechaDesde);
		}
		this.onSubmit();
	}
	addEventPickerDesde(event:any){
		this.reservaForm.controls["periodo"].setValue("personalizado")
		this.onSubmit();
	}
	addEventPickerHasta(event:any){
		this.reservaForm.controls["periodo"].setValue("personalizado")
		this.onSubmit();
	}
}
