import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Angular Flex Layout */
import { FlexLayoutModule } from "@angular/flex-layout";

/* Components */
import { LogInComponent } from './components/log-in/log-in.component';
import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from './components/register/register.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './components/_helpers';
import { NewUserComponent } from './components/new-user/new-user.component';
import { TablaUsuariosComponent } from './components/tabla-usuarios/tabla-usuarios.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { RestaurantesComponent } from './components/restaurantes/restaurantes.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { OlMapsModule } from './components/ol-maps/ol-maps.module';
import {DatePipe} from '@angular/common';
import {GoogleMapsModule} from '@angular/google-maps';
import { MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';

const MY_DATE_FORMATS = {
  parse: {
      dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' }
  },
  display: {
      dateInput: 'input',
      monthYearLabel: { year: 'numeric', month: 'short' },
      dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
      } else {
          return date.toDateString();
      }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    HomeComponent,
    NewUserComponent,
    TablaUsuariosComponent,
    LayoutComponent,
    ServiciosComponent,
    RestaurantesComponent,
    ReservasComponent,
    GaleriaComponent,
    ContactoComponent,
  ],
  imports: [
    CommonModule,
    OlMapsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    GoogleMapsModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //{provide: DateAdapter, useClass: AppDateAdapter},  
    //{provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
