import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { UserService } from '../_services';
import { User } from '../_models';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PeriodicElement {
  usuario:string;
  nombre: string;
  apellido: string;
  rol:string;
  
}
const ELEMENT_DATA: PeriodicElement[] = [
  {usuario:'nike',nombre:'Nicolas',apellido:'Perez',rol:'Admin'}
];


/**
 * @title Table with sorting
 */
@Component({
  selector: 'app-tabla-usuarios',
  templateUrl: './tabla-usuarios.component.html',
  styleUrls: ['./tabla-usuarios.component.css']
})
export class TablaUsuariosComponent implements OnInit {
  loading = false;
  users: User[];
  userForEdit=new User;
  editarUs= false;
  submitted = false;
  editForm: FormGroup;
  displayedColumns: string[] = ['nombre', 'apellido','usuario','rol', 'star'];
  dataSource = new MatTableDataSource();
  constructor(private userService: UserService,
              private formBuilder: FormBuilder,
              private router: Router,){}
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      usuario: ['', Validators.required],
      contraseña: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      dni: ['', Validators.required],
      email: ['', Validators.required],
      rol: ['', Validators.required],
    });
  this.loading = true;
    this.userService.getAll().subscribe(x=>{
      this.dataSource= new MatTableDataSource(x)
      setTimeout(() => this.dataSource.sort = this.sort);
      this.dataSource.paginator = this.paginator;
    })
  }
  get f() { return this.editForm.controls; }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  editar(id){
    this.editarUs=true;
    this.userService.getUser(id).subscribe(x=>{
      this.userForEdit=x
      this.editForm.setValue({
        usuario:x.usuario,
        contraseña:x.contraseña,
        nombre:x.nombre,
        apellido:x.apellido,
        dni:x.dni,
        email:x.email,
        rol:x.rol
      })
    })
  }
  enviarEdit(){
    console.log(this.editForm.value)
    this.userService.editar(this.editForm.value).subscribe(x=>{
    })
  }
  crear(){
    this.router.navigate(['/crear-usuario'])
  }
  volver(){
    this.editarUs=false;
    this.router.navigate(['/home'])
  }

}
